import React from "react"

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const DateResolver = ( date ) => {

      let d = new Date(date);
      let m = d.getMonth();
      

      return months[m] + ' ' + d.getDate() + ', ' + d.getFullYear();
}

export default DateResolver;