import React from "react"
import PropTypes from "prop-types"
import RichText from "./richText"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import DateResolver from "../utils/dateResolver";


const PostsList = ({ postsList, pageContext, highlightFirst, template }) => {

      //Pager pages
      const $pagerPages = [];
      for (let index = 0; index < pageContext.numPages; index++) {
            $pagerPages[index] = index;
      }      

      return (
            <>
            <div className={`c-blog-posts`}>

                  {postsList.length > 0 &&

                  <ul className="c-blog-posts__list">

                  {postsList.map((item, i) => {

                        let isFirst = i == 0 && highlightFirst

                        return (

                        <li className={`${isFirst ? 'c-blog-post-first-article' : 'c-blog-article' }`}>

                              <div className={`${isFirst ? 'c-blog-article c-blog-article--first' : ''}`}>
                              <article className={`${isFirst ? 'c-blog-article__holder' : ''}`} >

                              <Link to={`${item.node.url}`} className="c-blog-article__image">

                                    {item.node.data.image_preview && item.node.data.image_preview.url &&
                                    <GatsbyImage className="c-pic--abs c-pic" image={item.node.data.image_preview.gatsbyImageData} />
                                    }
                                    {item.node.data.category.document && <Link to={`${item.node.data.category.url}`} className="c-blog-article__category">{item.node.data.category.document.data.name}</Link>}
                              </Link>

                              <div className="c-blog-article__info">

                                    {item.node.data.author.document && 
                                    
                                    <Link to={`${item.node.data.author.url}`} className="c-blog-article__author">
                                          <div className="c-blog-article__author-img">
                                          {item.node.data.author.document.data.image && item.node.data.author.document.data.image.url &&
                                                <GatsbyImage className="c-pic--abs c-pic" image={item.node.data.author.document.data.image.gatsbyImageData} />
                                          }
                                          </div>

                                          {item.node.data.author.document.data.name}

                                    </Link>}
                                    
                                    <span className="c-blog-article__date">{DateResolver(item.node.last_publication_date)}</span>

                                    <h2 className="c-blog-article__title">
                                    <Link to={`${item.node.url}`}>{item.node.data.title}</Link>
                                    </h2>

                                    {item.node.data.description && <p className="c-blog-article__description">{item.node.data.description}</p>}
                                    <Link to={`${item.node.url}`} className="c-blog-article__link c-link u-tap-size">Read blog post<span className="c-btn__icon" /></Link>

                              </div>

                              </article>
                              </div>

                        </li>

                        )

                  })}

                  </ul>

                 
                  }

                  {pageContext.numPages > 1 &&      
                        <ul className="c-pager">

                              {pageContext.currentPage > 1 &&
                                    <li className="c-pager__item">
                                          {pageContext.currentPage - 1 == 1 ? <Link className={`c-pager__link c-pager__link--prev u-tap-size`} to={'/' + template}><span className="c-btn__icon"></span></Link> : 
                                          <Link className={`c-pager__link c-pager__link--prev  u-tap-size`} to={`/${template}/page/${pageContext.currentPage - 1}`}><span className="c-btn__icon"></span></Link>}
                                    </li>
                              }

                              {$pagerPages.map((item, i) => {
                                    return (
                                          <li className="c-pager__item">
                                                {i+1 == pageContext.currentPage ?
                                                      <div className={`c-pager__link u-tap-size is-active`}>{i + 1}</div>
                                                :
                                                i == 0 ? <Link className={`c-pager__link u-tap-size ${i+1 == pageContext.currentPage ? 'is-active' : ''} `} to={'/' + template}>{i + 1}</Link> :
                                                <Link className={`c-pager__link u-tap-size ${i+1 == pageContext.currentPage ? 'is-active' : ''} `} to={`/${template}/page/${i+1}`}>{i + 1}</Link>
                                                }
                                                
                                          </li>
                                    )
                              })}

                              {pageContext.currentPage != pageContext.numPages && 
                                    <li className="c-pager__item">
                                          <Link className={`c-pager__link c-pager__link--next  u-tap-size`} to={`/${template}/page/${pageContext.currentPage + 1}`}><span className="c-btn__icon"></span></Link>
                                    </li>
                              }

                        </ul>
                  }

            </div>

            </>
      )

}

PostsList.propTypes = {
      postsList: PropTypes.object,
}

export default PostsList