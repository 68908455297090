
import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import RichText from '../components/richText';
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import PostsList from "../components/postsList";


const BlogTag = ({ data, pageContext }) => {


  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);


  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $postsList = data.allPrismicBlogPost ? data.allPrismicBlogPost.edges : null;

  
  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs}>

      <Seo metadata={$metaData} title={pageContext.tag}></Seo> 

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/blog.pager.css')} rel="stylesheet" type="text/css" />

      </Helmet>

      
      <main>

        <section>

          <div className="c-blog-hero c-hero o-wrapper o-wrapper--xl c-heading">
              <Link to={`/blog`} className="c-blog-hero__link c-link u-tap-size"><span className="c-btn__icon" /> Return to Our Blog</Link>
            
              <div className="c-blog-heading__heading c-heading__heading">
                <h2>Filtering by Tag:     
                {' '}
                  <span className="c-blog-heading__author">#{pageContext.tag}</span>
                </h2>
              </div>
            
          </div>

          <div className="c-blog__wrapper">

            <div className="o-wrapper o-wrapper--xl">
              <div className={`c-blog__holder is-paginated`}>

                <div></div>
                <PostsList postsList={$postsList} pageContext={pageContext} highlightFirst={false} template={'blog/' + pageContext.uid + '/' + pageContext.tag}/>

              </div>

            </div>

          </div>

        </section>

      </main>

    </Layout>


  )
}

export const query = graphql`
  query tagQuery($skip: Int!, $limit: Int!, $tag: String!) {
 
    allPrismicBlogPost(
      filter: {data: {tags: {elemMatch: {tag: {glob: $tag}}}}}
      sort: {fields: first_publication_date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
         uid
         url
         first_publication_date
         last_publication_date
         data{
           title
           description
           image_preview {
            gatsbyImageData(
              
              breakpoints: [414,767,768,980,1024,1440,1680,1920]
            )
            url
          }
           image {
            gatsbyImageData(
              
              breakpoints: [414,767,768,980,1024,1440,1680,1920]
            )
            url
          }
           category {
            url
            uid
            document {
              ... on PrismicBlogCategory {
                data {
                  name
                }
              }
            }
          }
           author {
            url
            uid
            document {
              ... on PrismicBlogAuthor {
                data {
                  name
                  image {
                    gatsbyImageData(
                      
                      breakpoints: [414,767,768,980,1024,1440,1680,1920]
                    )
                    url
                  }
                }
              }
            }
          }
         }
        }
      }
    },
   
    
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default BlogTag
